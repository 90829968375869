'use strict';
/* jshint unused: vars */

var app = angular.module('directory');
app.controller('EventHandoutController', function ($scope, $log, $routeParams, User, Profiles, Events, EventsService, Messages, Notifier, $route, moment) {
  $log.debug('Controller: "EventHandoutController" instantiated. Event Id: ' + $routeParams.eventId);

  $scope.handoutTimestamp = new Date();
  $scope.event = null;
  $scope.eventHostName = '';
  $scope.eventGuests = null;
  $scope.lang = $routeParams.lang || 'en';

  $scope.handoutTimestampFr = moment(new Date()).locale('fr').format('LL à LT');

  $scope.switchLang = function () {
    $scope.lang = $scope.lang === 'en' ? $scope.lang = 'fr' : $scope.lang = 'en';
    $route.updateParams({
      lang: $scope.lang
    });
  };

  const parseEventContact = (event) => {
    if(event.eventSource === 'TMC_SCHED_HUB_HOSTED') {
      const systems = event.participants.filter(participant => !participant.isConsultantSystem);
      if(systems && systems.length > 0) {
        if(systems[0].type === 'LEGACY') {
          return systems[0].siteName;
        } else {
          return systems[0].name;
        }
      }
    } else {
      return (event && event.eventContact && event.eventContact.name) ? event.eventContact.name : '';
    }    
  };

  const parseEventGuests = (event) => (
    event.participants.filter(participant => !participant.isHost)
  );

  const parseEventConsultant = (event) => {
    if(event.eventContact) {
      Profiles.get({
        type: 'people',
        id: event.eventContact.id
      }, function (profile) {
        $scope.eventConsultantDetails = profile;
        $scope.eventConsultantDetails.name = event.eventContact.name;
      });       
    }
  };

  const parseEventSiteProfile = (event) => {
    const host = EventsService.getHostSystem(event);
    if(host) {
      Profiles.get({
        type: 'places',
        id: host.publishedProfileId
      }, function (profile) {
        $scope.eventSiteProfile = profile;
      });        
    }
  };

  const init = function () {
    var eventId = $routeParams.eventId;
    var userDetails = User.details;
    Events.get({
      id: eventId,
      delegator: userDetails.contactid
    }, function (response) {
      $scope.event = response;
      $scope.eventHostName = parseEventContact($scope.event);
      $scope.eventGuests = parseEventGuests($scope.event);
      $scope.eventConsultantDetails = parseEventConsultant($scope.event);
      parseEventSiteProfile($scope.event);
    }, function (rejection) {
      $log.error('Error getting event!', rejection.data);
      Notifier.createMessage('error', Messages.eventHandout.retrieve.failed.message, Messages.eventHandout.retrieve.failed.solution);
      $scope.event = null;
    });

    Events.auditLogging({
          id: eventId,
          actionType:'VIEW_HANDOUT'
    });
  };

  init();

});
